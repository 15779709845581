import { Anchor, Box } from "grommet";
import Link from "next/link";
import styled from "styled-components";

const StyledAnchor = styled(Anchor).attrs({ size: "small", color: "#1B2130" })`
  padding: 8px 12px;
  border-radius: 4px;
  min-width: 160px;
  &:hover {
    background-color: #e5e5e5;
    color: #1b2130;
  }
`;

export function DropdownLink({ href, ...rest }) {
  return (
    <Box tag="li">
      <Link href={href} passHref legacyBehavior>
        <StyledAnchor {...rest} />
      </Link>
    </Box>
  );
}

const Dropdown = styled(Box).attrs({
  tag: "ul",
  margin: "none",
  pad: { horizontal: "xsmall", vertical: "xsmall" },
  background: "white",
  round: "xxsmall",
})``;

export default Dropdown;
