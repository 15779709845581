import transformDate from "@helpers/transformDate";

function transformAirdrop(airdrop, policy) {
  if (!airdrop) return airdrop;

  const { startDate, endDate } = airdrop;
  return {
    ...airdrop,
    ...(startDate && { startDate: transformDate(startDate, policy) }),
    ...(endDate && { endDate: transformDate(endDate, policy) }),
  };
}

function transformAudit(audit, policy) {
  if (!audit) return audit;

  const { finishedAt, progressAt } = audit;

  return {
    ...audit,
    ...(finishedAt && { finishedAt: transformDate(finishedAt, policy) }),
    ...(progressAt && { progressAt: transformDate(progressAt, policy) }),
  };
}

function transformKYC(kyc, policy) {
  if (!kyc) return kyc;

  const { finishedAt, progressAt } = kyc;

  return {
    ...kyc,
    ...(finishedAt && { finishedAt: transformDate(finishedAt, policy) }),
    ...(progressAt && { progressAt: transformDate(progressAt, policy) }),
  };
}

function transformAMA(ama, policy) {
  if (!ama) return ama;

  const { createdAt, startedAt } = ama;

  return {
    ...ama,
    ...(createdAt && { createdAt: transformDate(createdAt, policy) }),
    ...(startedAt && { startedAt: transformDate(startedAt, policy) }),
  };
}

export default function transformResponse(
  {
    createdAt,
    deployedAt,
    presaleEndsAt,
    presaleStartsAt,
    airdrop,
    audit,
    kyc,
    ama,
    ...data
  },
  policy = "fromServer"
) {
  return {
    ...(createdAt !== undefined && {
      createdAt: transformDate(createdAt, policy),
    }),
    ...(deployedAt !== undefined && {
      deployedAt: transformDate(deployedAt, policy),
    }),
    ...(presaleEndsAt !== undefined && {
      presaleEndsAt: presaleEndsAt && transformDate(presaleEndsAt, policy),
    }),
    ...(presaleStartsAt !== undefined && {
      presaleStartsAt:
        presaleStartsAt && transformDate(presaleStartsAt, policy),
    }),
    ...(airdrop !== undefined && {
      airdrop: transformAirdrop(airdrop, policy),
    }),
    ...(audit !== undefined && { audit: transformAudit(audit, policy) }),
    ...(ama !== undefined && { ama: transformAMA(ama, policy) }),
    ...(kyc !== undefined && { kyc: transformKYC(kyc, policy) }),
    ...data,
  };
}
