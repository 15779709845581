import axios from "axios";
import useFetchPromise from "@cybertools/ui/es/hooks/useFetchPromise";
import useAuth from "@organisms/auth/useAuth";
import { useCoinDispatch } from "@organisms/coin/provider";
import transformResponse from "./transformResponse";

export const useFetchCoin = (slug, { onSuccess } = {}) => {
  const coinDispatch = useCoinDispatch();

  return useFetchPromise(
    {
      enabled: Boolean(slug),
      action: () =>
        axios.get(`/api/coins/${slug}`).then((response) => {
          coinDispatch((state) => ({
            ...state,
            [slug]: transformResponse(response.data),
          }));
          if (onSuccess) onSuccess(response.data);
        }),
    },
    [slug]
  );
};

const useCoinSlugsDispatch = () => {
  const coinDispatch = useCoinDispatch();

  return (bySlug, slugs) => {
    coinDispatch((state) =>
      slugs.reduce((acc, slug) => {
        const transformed = transformResponse(bySlug[slug]);

        return {
          ...acc,
          [slug]: {
            ...acc[slug],
            ...transformed,
            airdrop: (acc[slug]?.airdrop || transformed?.airdrop) && {
              ...acc[slug]?.airdrop,
              ...transformed?.airdrop,
            },
          },
        };
      }, state)
    );
  };
};

const cachedAxios = async (submitRequest, { age, key }) => {
  let payload = localStorage.getItem(key);

  try {
    payload = payload && JSON.parse(payload);
  } catch (error) {}

  if (payload && payload.createdAt + age * 1000 > Date.now()) {
    return { data: payload.data };
  }

  const { data } = await submitRequest();

  localStorage.setItem(key, JSON.stringify({ data, createdAt: Date.now() }));

  return { data };
};

export const useFetchPromotedCoins = (enabled) => {
  const coinSlugsDispatch = useCoinSlugsDispatch();
  const { user, loading } = useAuth();

  enabled = enabled && (!loading || user);

  return useFetchPromise(
    {
      enabled,
      action: async () => {
        const authorization = user && (await user.getIdToken());

        const response = await axios.get(`/api/coins/promoted`, {
          headers: { authorization },
        });

        const { bySlug, promotedSlugs } = response.data;

        coinSlugsDispatch(bySlug, promotedSlugs);

        return promotedSlugs;
      },
    },
    [enabled]
  );
};

export const useFetchAirdropPending = () => {
  const coinSlugsDispatch = useCoinSlugsDispatch();
  const auth = useAuth();

  const enabled = Boolean(auth.user);

  return useFetchPromise(
    {
      enabled,
      action: async () => {
        const authorization = await auth.user.getIdToken();

        const response = await cachedAxios(
          () =>
            axios.get(`/api/coins/airdropPending`, {
              headers: { authorization },
            }),
          {
            age: 2 * 60,
            key: `/api/coins/airdropPending`,
          }
        );

        const { bySlug, airdropPendingActionSlugs } = response.data;

        coinSlugsDispatch(bySlug, airdropPendingActionSlugs);

        return airdropPendingActionSlugs;
      },
      initialData: [],
    },
    [enabled]
  );
};

export const useFetchTrending = () => {
  const coinSlugsDispatch = useCoinSlugsDispatch();

  return useFetchPromise(
    {
      action: async () => {
        const response = await cachedAxios(
          () => axios.get(`/api/coins/trending`),
          {
            age: 2 * 60 * 60,
            key: `/api/coins/trending`,
          }
        );

        const { bySlug, trendingSlugs } = response.data;

        coinSlugsDispatch(bySlug, trendingSlugs);

        return trendingSlugs;
      },
      initialData: [],
    },
    []
  );
};
