import { utc0Date, serverOtc0Date } from "./utc0";

const policies = {
  fromServer: utc0Date,
  toServer: serverOtc0Date,
  default: (v) => new Date(v),
  fromDb: (v) => (v ? v.toJSON() : ""),
};

export default function transformDates(date, policy) {
  const converted = policies[policy] || policies.default;
  return converted(date);
}
